exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-business-js": () => import("./../../../src/pages/business.js" /* webpackChunkName: "component---src-pages-business-js" */),
  "component---src-pages-compliance-js": () => import("./../../../src/pages/compliance.js" /* webpackChunkName: "component---src-pages-compliance-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-ourteam-js": () => import("./../../../src/pages/ourteam.js" /* webpackChunkName: "component---src-pages-ourteam-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-statesrevenues-js": () => import("./../../../src/pages/statesrevenues.js" /* webpackChunkName: "component---src-pages-statesrevenues-js" */),
  "component---src-pages-taxcenter-js": () => import("./../../../src/pages/taxcenter.js" /* webpackChunkName: "component---src-pages-taxcenter-js" */),
  "component---src-pages-taxes-js": () => import("./../../../src/pages/taxes.js" /* webpackChunkName: "component---src-pages-taxes-js" */),
  "component---src-pages-taxrates-2021-js": () => import("./../../../src/pages/taxrates2021.js" /* webpackChunkName: "component---src-pages-taxrates-2021-js" */),
  "component---src-pages-taxrates-2022-js": () => import("./../../../src/pages/taxrates2022.js" /* webpackChunkName: "component---src-pages-taxrates-2022-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

